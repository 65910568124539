import React, { Component } from "react";


import Layout from "../components/layout"
import Banner from "../components/others/banner"
import Form from "../components/others/form"
import ReactHtmlParser from "react-html-parser"

import ReactMarkdown from 'react-markdown'

import { StaticQuery, graphql } from "gatsby"

const objectGraphql = graphql`
{
    strapiContacto(locale: {eq: "es-PE"}) {
      id
      formulario {
        btn {
          titulo
          id
        }
        correo_receptor
        id
        titulo
      }
      contacto {
        titulo
        elementos_de_contacto {
          titulo_telefono
          titulo_email
          telefono
          titulo_direccion
          id
          direccion
          email
        }
        id
        img {
          url
        }
      }
      Banner {
        fondo {
          url
        }
        url {
          titulo
          id
          enlace
        }
        titulo
        id
      }
    }
  }
  
`


class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectOffice: 1
        };

        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(item) {
        console.log(item);
        this.setState({
            selectOffice: item
        });
    }

    render() {


        return (
            <StaticQuery
                query={objectGraphql}
                render={data => (
                    <React.Fragment>
                        <Layout nameTab='Contacto' props={this.props.props}>
                            <Banner banner={data.strapiContacto.Banner} />
                            <section className="padding-section-int">
                                <div className="container">
                                    <div className="row margin-b-80">
                                        <div className="col-lg-2 tab-ver">
                                            <ul className="nav nav-tabs">
                                                {data.strapiContacto.contacto.map((item) => {
                                                    return (
                                                        <li style={{ cursor: 'pointer' }} key={item.id} className="nav-item">
                                                            <a className={`nav-link ${item.id === this.state.selectOffice ? 'active' : ''}`} onClick={this.handleClick.bind(this, item.id)}>{item.titulo}</a>
                                                        </li>
                                                    )
                                                })}

                                            </ul>
                                        </div>

                                        <div className="col-lg-10">
                                            <div className="tab-content">
                                                {data.strapiContacto.contacto.map((item) => {
                                                    return (
                                                        <div key={item.id} className={`tab-pane ${item.id === this.state.selectOffice ? 'active' : ''}`} id="oficina-3">
                                                            <h3>{item.titulo}</h3>
                                                            <p><img src={item.img.url} alt={item.img.url} className="img-fluid" /></p>
                                                            <h4>Datos de contacto</h4>

                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <p className="mb-0-p"><i className="fa fa-map-marker redo"></i> <strong>{item.elementos_de_contacto.titulo_direccion} </strong><br />
                                                                        <ReactMarkdown skipHtml={true}>
                                                                            {item.elementos_de_contacto.direccion}
                                                                        </ReactMarkdown>
                                                                    </p>
                                                                </div>
                                                                {item.elementos_de_contacto.telefono &&
                                                                    <div className="col-md-6">
                                                                        <p><i className="fa fa-phone redo"></i> <strong>{item.elementos_de_contacto.titulo_telefono}</strong><br />
                                                                            {item.elementos_de_contacto.telefono}</p>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <p><i className="fa fa-envelope-o redo"></i> <strong> {item.elementos_de_contacto.titulo_email}</strong><br />
                                                                        <a href="mailto:informes@centinela.com.pe"> {item.elementos_de_contacto.email}</a></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row margin-b-80">
                                        <div className="col-md-10 offset-md-2 margin-b-20">
                                            <h4>Envíenos un mensaje</h4>
                                        </div>
                                        <div className="col-md-10 offset-md-2">

                                            <Form data={data} />
                                        </div>
                                    </div> */}
                                </div>
                            </section>
                        </Layout>
                    </ React.Fragment>
                )}
            />
        )
    }
}

export default Contact
